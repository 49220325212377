import { render, staticRenderFns } from "./RequestResetPassword.vue?vue&type=template&id=4c7c609e&scoped=true"
import script from "./RequestResetPassword.vue?vue&type=script&lang=js"
export * from "./RequestResetPassword.vue?vue&type=script&lang=js"
import style0 from "./RequestResetPassword.vue?vue&type=style&index=0&id=4c7c609e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7c609e",
  null
  
)

export default component.exports